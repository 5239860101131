<template>
    <transition>
        <VideoPopup v-if="videoStatus" @close="hideVideo" />
    </transition>
    <section class="home">
        <video muted loop autoplay playsinline class="banner-video banerVideo-desktop" id="hero-banner-video">
            <source :src="require(`@/assets/videos/${videoSource}`)" type="video/mp4" />
        </video>

        <section class="home-content">
            <div class="home-content-top">
                <h1 class="anim" v-if="this.screenWidth >= 576">
                    <div class="div-anim" data-delay=".2s">{{ $t('herobannertitle.technologia') }}&nbsp;</div>
                    <div class="div-anim" data-delay=".4s">{{ $t('herobannertitle.sluzbie') }}&nbsp;</div>
                    <div class="div-anim" data-delay=".5s">{{ $t('herobannertitle.zdrowiu') }}</div>
                </h1>
                <h1 class="anim" v-if="this.screenWidth < 576">
                    <div class="div-anim" data-delay=".7s">{{ $t('herobannertitle.technologia') }}&nbsp;</div>
                </h1>
                <h1 class="anim" v-if="this.screenWidth < 576">
                    <div class="div-anim" data-delay=".9s">{{ $t('herobannertitle.sluzbie') }}&nbsp;</div>
                </h1>
                <h1 class="anim" v-if="this.screenWidth < 576">
                    <div class="div-anim" data-delay="1.1s">{{ $t('herobannertitle.zdrowiu') }}</div>
                </h1>
            </div>
            <div class="home-content-bottom">
                <div>
                    <button class="btn btn-anim" data-delay=".3s" @click="showVideo">{{ $t('herobannertitle.zobacz video') }}</button>
                </div>
                <div>
                    <button class="btn btn-anim" data-delay=".5s" @click="$router.push('products')">{{ $t('herobannertitle.poznaj produkty') }}</button>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import VideoPopup from "@/components/default/VideoPopup.vue";

export default {
    components: {
        VideoPopup,
    },
    data() {
        return {
            videoStatus: false,
            videoSource: "baner_video.mp4",
        };
    },
    computed: {
        screenWidth() {
            return this.$store.state.screenWidth;
        },
    },
    // mounted() {
    //     this.playVideo();
    // },
    methods: {
        goto(id) {
            const yOffset = -150;
            const element = document.getElementById(id);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
        },

        showVideo() {
            this.videoStatus = true;
            document.querySelector("body").style.overflowY = "hidden";
            document.getElementById("hero-banner-video")?.pause();
        },
        hideVideo() {
            this.videoStatus = false;
            document.querySelector("body").style.overflowY = "initial";
            document.getElementById("hero-banner-video")?.play();
        },
        // videoTimeout() {
        //     setTimeout(() => {
        //         document.getElementById("hero-banner-video").play();
        //     }, 1500);
        // },
        // playVideo() {
        //     const video = document.getElementById("hero-banner-video");
        //     video.oncanplaythrough = () => {
        //         video?.play();
        //     };
        // },
    },
};
</script>

<style scoped>
.v-enter-from {
    opacity: 0;
    transform: translateY(-100vh);
}
.v-enter-active {
    transition: all 0.3s ease-out;
}
.v-enter-to {
    opacity: 1;
    transform: translateY(0);
}
.v-leave-from {
    opacity: 1;
    transform: translateY(0);
}
.v-leave-active {
    transition: all 0.3s ease-in;
}
.v-leave-to {
    opacity: 0;
    transform: translateY(-100vh);
}
</style>
