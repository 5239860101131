<template>
    <section class="home-gallery">
        <div class="home-gallery-container">
            <div class="home-gallery-container-fixed">
                <h1>Klienci o mnie</h1>
                <!-- <h1>Opinie naszych klientów</h1>
                <p class="paragraph-up">
                    Zadowolenie klientów<br class="mobile-br" />
                    motywuje nas do dalszej<br class="mobile-br" />
                    pracy i rozwoju naszych<br class="mobile-br" />
                    produktów.
                </p>
                <p class="paragraph-bottom">Paweł Gawroński<br />Wiceprezes</p>
                <p class="signature">Gawroński</p> -->
            </div>
            <div class="home-gallery-container-slider" id="gallerySlider">
                <transition :name="changeDirection">
                    <div class="slide" v-if="slideIndex === 0">
                        <h2>
                            1Quis autem vel eum iure reprehe derit qui in ea voluptate velit esse Sed ut perspiciatis unde omnis
                            iste natus error sit voluptatem accusantium rem aperiam quam nihil molestiae consequatur, vel illum
                            qui dolorem eum fugiat quo voluptas nulla pariatur.
                        </h2>
                        <p>
                            dr n. med. Jan Kowalski <br />
                            Wojewódzki Szpital Specjalistyczny im. J. Gromkowskiego
                        </p>
                    </div>
                </transition>
                <transition :name="changeDirection">
                    <div class="slide" v-if="slideIndex === 1">
                        <h2>
                            2Quis autem vel eum iure reprehe derit qui in ea voluptate velit esse Sed ut perspiciatis unde omnis
                            iste natus error sit voluptatem accusantium rem aperiam quam nihil molestiae consequatur, vel illum
                            qui dolorem eum fugiat quo voluptas nulla pariatur.
                        </h2>
                        <p>
                            dr n. med. Jan Kowalski <br />
                            Wojewódzki Szpital Specjalistyczny im. J. Gromkowskiego
                        </p>
                    </div>
                </transition>
                <transition :name="changeDirection">
                    <div class="slide" v-if="slideIndex === 2">
                        <h2>
                            3Quis autem vel eum iure reprehe derit qui in ea voluptate velit esse Sed ut perspiciatis unde omnis
                            iste natus error sit voluptatem accusantium rem aperiam quam nihil molestiae consequatur, vel illum
                            qui dolorem eum fugiat quo voluptas nulla pariatur.
                        </h2>
                        <p>
                            dr n. med. Jan Kowalski <br />
                            Wojewódzki Szpital Specjalistyczny im. J. Gromkowskiego
                        </p>
                    </div>
                </transition>
                <transition :name="changeDirection">
                    <div class="slide" v-if="slideIndex === 3">
                        <h2>
                            4Quis autem vel eum iure reprehe derit qui in ea voluptate velit esse Sed ut perspiciatis unde omnis
                            iste natus error sit voluptatem accusantium rem aperiam quam nihil molestiae consequatur, vel illum
                            qui dolorem eum fugiat quo voluptas nulla pariatur.
                        </h2>
                        <p>
                            dr n. med. Jan Kowalski <br />
                            Wojewódzki Szpital Specjalistyczny im. J. Gromkowskiego
                        </p>
                    </div>
                </transition>
            </div>
            <div></div>
            <div class="home-gallery-container-dots">
                <span class="dot" :class="slideIndex === 0 ? 'active' : null" @click="sliderNav(0)"></span>
                <span class="dot" :class="slideIndex === 1 ? 'active' : null" @click="sliderNav(1)"></span>
                <span class="dot" :class="slideIndex === 2 ? 'active' : null" @click="sliderNav(2)"></span>
                <span class="dot" :class="slideIndex === 3 ? 'active' : null" @click="sliderNav(3)"></span>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            slideIndex: 0,
            galleryInterval: null,
            changeDirection: "left",
        };
    },
    mounted() {
        this.galleryTimeout();
        this.changeSlideIndex();

        let touchstartX = 0;
        let touchendX = 0;

        const slider = document.getElementById("gallerySlider");

        slider.addEventListener("touchstart", (e) => {
            touchstartX = e.changedTouches[0].screenX;
        });

        slider.addEventListener("touchend", (e) => {
            touchendX = e.changedTouches[0].screenX;
            if (touchendX < touchstartX && touchstartX - touchendX > 80) {
                this.changeDirection = "left";
                if (this.slideIndex < 3) {
                    this.slideIndex++;
                } else {
                    this.slideIndex = 0;
                }
                clearInterval(this.galleryInterval);
                this.galleryTimeout();
            }
            if (touchendX > touchstartX && touchendX - touchstartX > 80) {
                this.changeDirection = "right";
                if (this.slideIndex === 0) {
                    this.slideIndex = 3;
                } else {
                    this.slideIndex--;
                }
                clearInterval(this.galleryInterval);
                this.galleryTimeout();
            }
        });
    },
    methods: {
        galleryTimeout() {
            this.galleryInterval = setInterval(
                function () {
                    this.changeSlideIndex();
                }.bind(this),
                5000
            );
        },
        changeSlideIndex() {
            this.changeDirection = "left";

            if (this.slideIndex < 3) {
                this.slideIndex++;
            } else {
                this.slideIndex = 0;
            }
            //console.log(this.slideIndex);
        },
        sliderNav(id) {
            this.slideIndex = id;
            clearInterval(this.galleryInterval);
            this.galleryTimeout();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";
.active {
    background-color: $light-blue;
}

.home-gallery {
    overflow: hidden;
}

.left-enter-from {
    transform: translateX(100%);
    opacity: 0;
}
.left-enter-active {
    transition: all 0.8s ease-in-out;
}
.left-enter-to {
    transform: translateX(0);
    opacity: 1;
}
.left-leave-from {
    transform: translateX(0);
    opacity: 1;
}
.left-leave-active {
    transition: all 0.8s ease-in-out;
}
.left-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}

.right-enter-from {
    transform: translateX(-100%);
    opacity: 0;
}
.right-enter-active {
    transition: all 0.8s ease-in-out;
}
.right-enter-to {
    transform: translateX(0);
    opacity: 1;
}
.right-leave-from {
    transform: translateX(0);
    opacity: 1;
}
.right-leave-active {
    transition: all 0.8s ease-in-out;
}
.right-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>
