<template>
    <h1>
        {{ $t('homeProdukty.poznaj')}} <br class="mobile-br" />
        {{ $t('homeProdukty.produkty')}}
    </h1>
    <h2>
        {{ $t('homeProdukty.zawsze')}}<br class="desktop-br" />
        {{ $t('homeProdukty.tworzac')}}
    </h2>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

h1 {
    margin-top: 105px;
    color: $primary-blue;
    text-align: center;
    margin-bottom: 29px;
    letter-spacing: -2.48px;
}
h2 {
    margin: 0 auto 100px auto;
    color: $primary-blue;
    text-align: center;
    letter-spacing: 0;
}
@include respond-below(lg) {
    h1 {
        margin: 55px auto 29px auto;
        width: 85vw;
    }
    h2 {
        width: 85vw;
        margin: 0 auto 50px auto;
        .mobile-br {
            display: block;
        }
        .desktop-br {
            display: none;
        }
    }
}
@include respond-below(sm) {
    h1 {
        margin: 55px auto 28px auto;
        letter-spacing: -1.12px;
    }
    h2 {
        margin: 28px auto 57px auto;
        width: 70%;
        letter-spacing: -0.3px;
        line-height: 18px;
    }
}
</style>
