<template>
    <section class="home-blog">
        <div>
            <div class="background1">
                <h3>
                    Najnowsze <br class="mobile-br" />
                    wpisy<br />
                    na blogu
                </h3>
            </div>
        </div>
        <div>
            <div class="background2">
                <div></div>
            </div>
            <h4>
                Quis autem vel eum iure <br class="desktop-br" />reprehe derit qui in ea<br class="desktop-br" />
                Sed ut perspiciatis unde
            </h4>
            <p>Czytaj więcej</p>
        </div>
        <div>
            <div class="background3">
                <div></div>
            </div>
            <h4>
                Quis autem vel eum iure<br class="desktop-br" />
                reprehe derit qui in ea <br class="desktop-br" />Sed ut perspiciatis unde
            </h4>
            <p>Czytaj więcej</p>
        </div>
    </section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.home-blog {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    grid-template-rows: 400px;
    justify-content: center;
    margin-bottom: 110px;
    div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .background1 {
            width: 100%;
            height: 50%;
            background-color: $light-blue;
        }
        .background2 {
            background-image: url("~@/assets/images/blog.png");
            background-size: cover;
            width: 100%;
            height: 50%;
            border-top: 5px solid $primary-blue;
        }
        .background3 {
            background-image: url("~@/assets/images/blog.png");
            background-size: cover;
            width: 100%;
            height: 50%;
            border-top: 5px solid $dark-blue;
        }
        h3 {
            color: white;
            font-size: $fontSize23;
            letter-spacing: 0;
            line-height: 26.69px;
            font-weight: lighter;
            width: 36%;
            padding: 25px 0 0 30px;
        }
        h4 {
            padding: 28px 0 0 14px;
            font-size: $fontSize23;
            color: $primary-blue;
            line-height: 29.39px;
            letter-spacing: 0;
            font-weight: lighter;
        }
        p {
            padding: 29px 0 0 14px;
            color: $primary-blue;
            cursor: pointer;
            font-size: $fontSize16;
            line-height: 19.2px;
            display: block;
            position: relative;
            width: auto;
        }
        @include respond-above(lg) {
            p:after {
                content: "";
                position: absolute;
                width: 90%;
                transform: scaleX(0);
                height: 1px;
                bottom: 2px;
                left: 14px;
                background-color: $primary-blue;
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
            }
            p:hover:after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }
    @include respond-below(xl) {
        grid-template-columns: repeat(3, calc(90vw / 3));
        grid-template-rows: 400px;
    }
    @include respond-below(lg) {
        grid-template-columns: 85vw;
        grid-template-rows: 30vw 70vw 70vw;
        row-gap: 30px;
        div {
            .background1 {
                height: 100%;
            }
            h3 {
                font-size: $fontSize32;
                line-height: normal;
            }
        }
    }
    @include respond-below(sm) {
        margin: 0;
        padding: 0px 0 60px 0;
        grid-template-columns: minmax($mobile-grid-width-min, $mobile-grid-width-max);
        grid-template-rows: 150px 290px 290px;
        div {
            .background2,
            .background3 {
                div {
                    min-width: $mobile-grid-width-min;
                    max-width: $mobile-grid-width-max;
                    background: $primary-blue;
                }
            }
            h3 {
                font-size: $fontSize30;
                letter-spacing: -0.6px;
                line-height: 30px;
                width: 50%;
                padding: 23px 0 0 28px;
            }
            h4 {
                padding: 27px 0 0 27px;
                font-size: $fontSize15;
                width: 80%;
                line-height: 21.39px;
                letter-spacing: 0;
                font-weight: lighter;
            }
            p {
                padding: 27px 0 0 27px;
                color: $primary-blue;
                cursor: pointer;
                font-size: $fontSize15;
                line-height: 19.2px;
            }
        }
    }
}
</style>
