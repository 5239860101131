<template>
	<section class="home-achievements">
		<div id="blank-fill"></div>
		<h1 class="anim" v-if="this.$store.state.screenWidth >= 992">
			<div class="div-anim" data-delay=".1s">{{ $t("homeImplementations.nasze") }}&nbsp;</div>
			<div class="div-anim" data-delay=".3s">{{ $t("homeImplementations.sa") }}</div>
			<br />
			<div class="div-anim" data-delay=".3s">{{ $t("homeImplementations.satysfakcji") }}&nbsp;</div>
			<div class="div-anim" data-delay=".45s">{{ $t("homeImplementations.dla") }}</div>
			<br />
			<div class="div-anim" data-delay=".45s">{{ $t("homeImplementations.klientow") }}&nbsp;</div>
			<div class="div-anim" data-delay=".6s">{{ $t("homeImplementations.i") }}</div>
		</h1>
		<h1 class="anim" v-if="this.$store.state.screenWidth < 992">
			<div class="div-anim" data-delay=".1s">{{ $t("homeImplementations.nasze") }}</div>
			<br />
			<div class="div-anim" data-delay=".3s">{{ $t("homeImplementations.sa") }} {{ $t("homeImplementations.satysfakcji") }}</div>
			<br />
			<div class="div-anim" data-delay=".5s">{{ $t("homeImplementations.dla") }}</div>
			<br />
			<div class="div-anim" data-delay=".7s">{{ $t("homeImplementations.klientow") }} {{ $t("homeImplementations.i") }}</div>
		</h1>
		<div v-if="$i18n.locale === 'en-US'">
			<button style="width: 260px" class="btn button-wide" @click="$router.push('products')">
				{{ $t("homeImplementations.button") }}
			</button>
		</div>
		<div v-if="$i18n.locale === 'pl-PL'">
			<button class="btn button-wide" @click="$router.push('products')">{{ $t("homeImplementations.button") }}</button>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.home-achievements {
	height: 100vh;
	background-image: url("~@/assets/images/tablet.jpg");
	background-position: center 20%;
	@include flex-center-column;
	#blank-fill {
		display: none;
	}
	h1 {
		text-align: center;
		letter-spacing: 0px;
	}
	.btn {
		margin-top: 38px;
	}
	@include respond-below(md) {
		height: 70vh;
		.mobile-br {
			display: block;
		}
		.desktop-br {
			display: none;
		}
	}
	@include respond-below(sm) {
		height: auto;
		background: none;
		#blank-fill {
			display: block;
			background-image: url("~@/assets/images/tablet.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 55vh;
			width: 100%;
		}
		h1 {
			height: auto;
			padding: 24px 0 22px 0;
			color: $primary-blue;
			width: 100%;
			letter-spacing: -1.12px;
			line-height: 32.92px;
		}
		.btn {
			width: 188px;
			margin: 0;
			margin-bottom: 20px;
		}
	}
}
</style>
