<template>
    <section class="home-about">
        <div class="home-about-up">
            <h1 class="anim" v-if="this.screenWidth >= 992">
                <div class="div-anim" data-delay=".1s">{{ $t('homeWeWantDesktop.chcemy') }}&nbsp;</div>
                <div class="div-anim" data-delay=".2s">{{ $t('homeWeWantDesktop.aby') }}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantDesktop.dla') }}&nbsp;</div>
                <div class="div-anim" data-delay=".4s">{{ $t('homeWeWantDesktop.i') }}</div>
            </h1>

            <h1 class="anim" v-if="this.screenWidth < 992">
                <div class="div-anim" data-delay=".2s">{{ $t('homeWeWantMobile.chcemy') }}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantMobile.aby') }}</div>
                <br />
                <div class="div-anim" data-delay=".4s">{{ $t('homeWeWantMobile.i') }}</div>
            </h1>

            <h2 class="anim" v-if="this.screenWidth >= 992">
                <div class="div-anim" data-delay=".7s">{{ $t('homeWeWantDescription.znamy')}}</div>
                <br />
                <div class="div-anim" data-delay=".7s">{{ $t('homeWeWantDescription.jest')}}</div>
                <br />
                <div class="div-anim" data-delay=".7s">{{ $t('homeWeWantDescription.pacjenta')}}</div>
            </h2>

            <h2 class="anim" v-if="this.screenWidth < 992">
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantDescriptionMobile.znamy')}}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantDescriptionMobile.od')}}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantDescriptionMobile.jest')}}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantDescriptionMobile.zoptymalizowac')}}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantDescriptionMobile.i')}}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('homeWeWantDescriptionMobile.systemy')}}</div>
            </h2>
            <h4 class="signature anim"><div class="div-anim" data-delay=".8s">Michał Kasiewicz</div></h4>
        </div>
        <div class="home-about-bottom">
            <div>
                <button class="btn button-white" data-delay="0s" @click="$router.push('about-us')">{{ $t('nav.o nas')}}</button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        screenWidth() {
            return this.$store.state.screenWidth;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.home-about {
    background-color: $primary-blue;
    height: 80vh;
    position: relative;
    &-up {
        height: 90%;
        @include flex-center-column;
    }
    h1 {
        text-align: center;
        margin-bottom: 26px;
    }
    h2 {
        text-align: center;
        margin-bottom: 26px;
    }
    &-bottom {
        bottom: 54px;
        position: absolute;
        text-align: center;
        width: 100%;
        .btn {
            margin: 0 auto;
        }
    }
    @include respond-below(xl) {
        h1 {
            width: 85vw;
        }
        h2 {
            width: auto;
        }
    }
    @include respond-below(lg) {
        padding: 90px 0 90px 0;
        height: auto;
        &-up {
            height: auto;
            h1 {
                width: 85%;
                margin-bottom: 22px;
                font-size: $fontSize48;
            }
            h2 {
                margin-bottom: 25px;
                font-size: $fontSize20;
            }
        }
        &-bottom {
            height: auto;
            margin: 68px 0 0 0;
            position: relative;
            bottom: 0;
        }
    }
    @include respond-below(sm) {
        padding: 54px 0 33px 0;
        height: auto;
        &-up {
            height: auto;
            h1 {
                width: 100%;
                letter-spacing: -1.2px;
                line-height: 33px;
                margin-bottom: 22px;
                font-size: $fontSize30;
            }
            h2 {
                letter-spacing: -0.3px;
                line-height: 18px;
                margin-bottom: 25px;
                font-size: $fontSize15;
            }
        }
        &-bottom {
            height: auto;
            margin: 68px 0 0 0;
            position: relative;
            bottom: 0;
        }
        .button-white {
            text-align: center;
            width: $mobile-button-width;
        }
    }
}
</style>
